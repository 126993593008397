<kendo-card [width]="this.cardWidth">
    <kendo-card-header>
        <span class="d-inline-block title-responsive">{{ product.Name }}</span>
        <div class="mt-1 d-flex justify-content-center align-items-center square-container">
            <img class="can-img img-fluid" *ngIf="!this.loading" [src]="imageUrl" [style.opacity]="imageOpacity"
                (load)="onImageLoad()" (error)="onImageError($event)" title="Can Image">
            <ntw-loading-panel *ngIf="this.loading || this.imageLoading" [fullscreen]="false" type="pulsing"
                themeColor="primary"></ntw-loading-panel>
        </div>
    </kendo-card-header>
    <kendo-card-body>
        <div class="container">
            <div class="row">
                <div class="col-12 pe-1 d-flex justify-content-between align-items-center">
                    <p class="my-1">
                        <strong>{{ 'portalOrderLine.AXCode' | translate }}:</strong>
                    </p>
                    <button kendoButton class="ms-2" (click)="this.onViewDetails()">
                        <kendo-svg-icon [icon]="this.icons.info"></kendo-svg-icon>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="mb-1">
                        {{ product.AXCode }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="my-1">
                        <strong>{{ 'portalOrderLine.palletSizeName' | translate }}:</strong> {{
                        product.PalletSizeName
                        }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="my-1">
                        <strong>{{ 'orderProcess.products.inventory' | translate }}:</strong>
                        {{ (product.Inventory ?? ('shared.unknown' | translate)) | number:'1.0-0' }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="my-1">
                        <strong>{{ 'portalOrderLine.unitNetPrice' | translate }}:</strong>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="mb-1">
                        {{ getUnitNetPrice() }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex flex-column align-items-center">
                    <kendo-numerictextbox class="mb-2" [(ngModel)]="this.quantity" [min]="1" [format]="'n0'" [step]="1">
                    </kendo-numerictextbox>
                    <button kendoButton class="d-flex justify-content-center align-items-center primaryButton"
                        (click)="this.onAddToCart()">
                        <kendo-svg-icon [icon]="this.icons.cart"></kendo-svg-icon>
                        {{ 'product.addToCart' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </kendo-card-body>
</kendo-card>
