import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { CardType } from '../../constants/account-details-card-type';

@Component({
  selector: 'ntw-account-detail-card',
  templateUrl: './account-detail-card.component.html',
  styleUrls: ['./../../../account/account-detail/account-detail.component.scss', './../../../shared/styles/button.scss']
})
export class AccountDetailCardComponent {
  @Input() title: string = '';
  @Input() icon: SVGIcon | null = null;
  @Input() buttonIcon?: SVGIcon | null = null;
  @Input() type: CardType = CardType.Button;
  @Input() action: () => void = () => {};
  @Input() inputPlaceholder?: string = '';

  @Input() searchValue: string = '';
  @Output() searchValueChange: EventEmitter<string> = new EventEmitter<string>();

  onSearchValueChange(): void {
    this.searchValueChange.emit(this.searchValue);
  }

  onSearch(): void {
    this.action();
  }
}
