import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { NotificationRef } from '@progress/kendo-angular-notification';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StringConstants } from './shared/constants/string-constants';
import { PortalRight } from './shared/models/portal-rights';
import { SecurityService } from './shared/security/security.service';
import { IdleService } from './shared/services/idle/idle.service';
import { KendoAlertService } from './shared/services/kendo-alerts.service';
import { CustomLanguageService } from './shared/services/language.service';
import { MaintenanceService } from './shared/services/maintenance/maintenance.service';
import { CompanyAdminConfigurationStore } from './shared/stores/company-admin-configuration.store';
import { UserSettingsStore } from './shared/stores/user-settings.store';

@Component({
  selector: 'ntw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Customer Portal";
  loading = true;
  isIframe = false;

  public isMaintenanceDialogOpened = false;

  public closeMaintenanceDialog = () => {
    this.isMaintenanceDialogOpened = false;
  }

  private _securityObject: AccountInfo = null;
  private readonly _destroying$ = new Subject<void>();

  public get securityObject(): AccountInfo {
    return this._securityObject;
  }
  public set securityObject(accountInfo: AccountInfo) {
    this._securityObject = accountInfo;
    if (accountInfo) {
      this.securityService.setUser(accountInfo);
      this.securityService.setUserRoles(this.GetUserPortalRights(accountInfo));
      this.setLanguageFromSettings(accountInfo.localAccountId);
    }
  }

  isMaintenanceActive = false;

  constructor(
    private securityService: SecurityService,
    private translateService: TranslateService,
    private languageService: CustomLanguageService,
    private msalBroadcastService: MsalBroadcastService,
    private userSettingsStore: UserSettingsStore,
    private authService: MsalService,
    private idleService: IdleService,
    private kendoAlertService: KendoAlertService,
    private router: Router,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private maintenanceService: MaintenanceService,
  ) { }

  ngOnInit(): void {
    this.companyAdminConfigurationStore.isLoading().subscribe({
      next: isLoading => {
        const isAdminConfigLoaded = this.companyAdminConfigurationStore.isLoaded();
        this.loading = isLoading && !isAdminConfigLoaded;
      }
    });
    this.maintenanceService.isAnnouncementActive.subscribe({
      next: isAnnouncementActive => {
        if (isAnnouncementActive) {
          this.isMaintenanceDialogOpened = true;
        }
      }
    });
    this.maintenanceService.isMaintenanceActive.subscribe({
      next: isMaintenanceActive => {
        this.isMaintenanceActive = isMaintenanceActive;
        if (isMaintenanceActive) {
          this.isMaintenanceDialogOpened = true;
        }
      }
    });

    this.isIframe = window !== window.parent && !window.opener;
    this.translateService.use(this.languageService.currentLanguage);
    this.handleMsalEvents();
    this.securityObject = this.authService.instance.getActiveAccount();

    this.setupTimeout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  private handleMsalEvents() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.securityObject = this.authService.instance.getActiveAccount();
        this.router.navigate([""]);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.error('login failed');
        console.error(result);
        this.authService.loginRedirect();
      });
  }

  private GetUserPortalRights(accountInfo: AccountInfo) {
    if (!accountInfo?.idTokenClaims['extension_Rights']) {
      return [];
    }
    const rightsJsonObj = JSON.parse(accountInfo.idTokenClaims['extension_Rights'] as string);
    if (!rightsJsonObj) {
      return [];
    }
    const rights: PortalRight[] = <PortalRight[]>rightsJsonObj;
    if (!rights) {
      return [];
    }

    const customerPortalRights: PortalRight[] = rights.filter(o => o.Name == StringConstants.B2CExtensionAttribute_Rights_CustomerPortal);
    if (!customerPortalRights || customerPortalRights.length != 1) {
      return [];
    }
    return customerPortalRights[0].Roles;
  }

  private setLanguageFromSettings(userB2CId: string) {
    this.userSettingsStore.load(userB2CId).subscribe({
      next: userSettings => {
        if (!userSettings) {
          this.languageService.currentLanguage = this.languageService.browserLanguage;
        }
        else if (userSettings?.languageCode && userSettings.languageCode != this.languageService.currentLanguage) {
          this.languageService.currentLanguage = userSettings.languageCode;
        }
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
      }
    });
  }

  private logoutNotification?: NotificationRef;

  private setupTimeout() {
    this.idleService.onIdleEnd.pipe(takeUntil(this._destroying$)).subscribe(() => {
      this.logoutNotification?.afterHide.subscribe(() => {
        this.logoutNotification = undefined;
        this.kendoAlertService.showSuccessAlert(this.translateService.instant('idle.welcomeBack'), true, 2000);
      });
      this.logoutNotification?.hide();
    });

    this.idleService.onTimeoutWarning.pipe(takeUntil(this._destroying$)).subscribe((countdown) => {
      if (!this.logoutNotification) {
        this.logoutNotification = this.kendoAlertService.showWarningAlert(this.getUntilTimeoutMessage(countdown));
      }
      else {
        this.logoutNotification.notification.instance.templateString = this.getUntilTimeoutMessage(countdown);
      }
    });

    this.idleService.onTimeout.pipe(takeUntil(this._destroying$)).subscribe(() => {
      if (this.logoutNotification) {
        this.logoutNotification.notification.instance.templateString = this.translateService.instant('idle.timeout');
      }
      else {
        this.kendoAlertService.showWarningAlert(this.translateService.instant('idle.timeout'));
      }
      this.idleService.watch();
      this.securityService.reset();
      this.authService.logout();
    });

    this.idleService.watch();
  }

  private getUntilTimeoutMessage(timeoutAfter: number) {
    let message = this.translateService.instant('idle.idle');

    if (timeoutAfter > 60) {
      message += ` ${Math.floor(timeoutAfter / 60)} ${this.translateService.instant('idle.minutes')}`;

      if (timeoutAfter % 60 > 0) {
        message += ` ${timeoutAfter % 60} ${this.translateService.instant('idle.seconds')}`;
      }
      message += '.';
    }
    else {
      message += ` ${timeoutAfter} ${this.translateService.instant('idle.seconds')}.`;
    }

    return message;
  }
}
