import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cartIcon, chevronDownIcon, paperPlaneIcon, qrCodeIcon, questionCircleIcon, searchIcon } from '@progress/kendo-svg-icons';
import * as QRCode from 'qrcode';
import { Observable, of } from 'rxjs';
import { ChartSeries } from 'src/app/shared/constants/chart-series-colors';
import { CompanyCode } from 'src/app/shared/constants/company-code';
import { SalesDistrict } from 'src/app/shared/constants/sales-district';
import { CrmAccount, MdsStatus } from 'src/app/shared/models/account';
import { CompanyAdminConfiguration } from 'src/app/shared/models/company-admin-configuration';
import { CrmContact } from 'src/app/shared/models/contact';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { CustomerSettings } from 'src/app/shared/models/customer-settings';
import { DashboardCard, DashboardCardVisibleFor } from 'src/app/shared/models/dashboard-card';
import { Warehouse } from 'src/app/shared/models/warehouse';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerSettingsService } from 'src/app/shared/services/customer-settings/customer-settings.service.interface';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { IPrintService } from 'src/app/shared/services/print/print.service.interface';
import { WarehouseService } from 'src/app/shared/services/warehouse/warehouse.service';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { CurrentUserContactStore } from 'src/app/shared/stores/current-user-contact.store';
import { CustomerSettingsStore } from 'src/app/shared/stores/customer-settings.store';
import { TranslateService } from '@ngx-translate/core';
import { CardType } from 'src/app/shared/constants/account-details-card-type';

@Component({
  selector: 'ntw-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss', '../../shared/styles/button.scss'],
  animations: [
    trigger('collapse', [
      state('open', style({
        display: '*',
        height: '*',
      })),
      state('closed', style({
        display: 'none',
        height: '0',
      })),
      transition('open => closed', [
        // add overflow clip to prevent content from overflowing during animation
        style({ overflow: 'clip' }),
        // animate the wrapper element height to 0
        animate('0.35s ease', style({ height: '0', paddingTop: '0', paddingBottom: '0' })),
      ],
      ),
      transition('closed => open', [
        // show the wrapper element
        style({ display: '*', overflow: 'clip', paddingTop: '0', paddingBottom: '0' }),
        // animate the wrapper height to its final value
        animate('0.35s ease', style({ height: '*', paddingTop: '*', paddingBottom: '*' })),
      ]),
    ]),
    trigger('rotate', [
      state('collapsed', style({ transform: 'rotate(180deg)' })),
      state('expanded', style({ transform: 'rotate(0deg)' })),
      transition('collapsed => expanded', [
        style({ backgroundColor: 'transparent' }),
        animate('0.35s ease', style({ transform: 'rotate(0deg)' }))
      ]),
      transition('expanded => collapsed', [
        style({ backgroundColor: 'transparent' }),
        animate('0.35s ease', style({ transform: 'rotate(180deg)' }))
      ]),
    ]),
  ]
})
export class AccountDetailComponent implements OnInit {
  public isCollapsed = false;
  public isSubmittingPrintAccountStatementRequest = false;
  public currentAccount: CrmAccount;
  public icons = {
    cart: cartIcon,
    chevronDown: chevronDownIcon,
    go: paperPlaneIcon,
    search: searchIcon,
    questionMark: questionCircleIcon,
    qrCode: qrCodeIcon
  };
  public isQrCodeDialogVisible = false;
  public generatedQrCodeData: string = '';

  loading = true;
  customer?: CosmosCustomer;
  customerSettings?: CustomerSettings;
  currentContact?: CrmContact;
  chartSeriesColor = ChartSeries.colors;
  dashboardCards: DashboardCard[] = [];

  warehousesLoading = true;
  warehouses: Warehouse[] = [];
  public get warehouseDropdownListData() {
    if (!this.prefferedWarehouse) {
      return this.warehouses;
    }
    else {
      // Exclude the preffered warehouse from the list because it is added as the default selected item
      return this.warehouses.filter(w => w.bkWarehouseKey !== this.prefferedWarehouse?.bkWarehouseKey);
    }
  }
  prefferedWarehouse?: Warehouse;

  isAlcro = false;
  isBeckers = false;

  items = [
    {
      title: 'product.findProducts',
      icon: this.icons.search,
      action: () => this.searchForProducts(),
      type: CardType.Search,
      inputPlaceholder: 'product.searchForProducts'
    },
    {
      title: 'routing.userGuide',
      icon: this.icons.questionMark,
      action: () => this.onUserGuideClick(),
      type: CardType.Button,
      buttonIcon: this.icons.go
    },
    {
      title: 'routing.openOrders',
      icon: this.icons.cart,
      action: () => this.onMyCartClick(),
      type: CardType.Button,
      buttonIcon: this.icons.go
    }
  ];

  constructor(
    private activeRoute: ActivatedRoute,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private currentUserContactStore: CurrentUserContactStore,
    private customerService: ICustomerService,
    private customerSettingsService: ICustomerSettingsService,
    private customerSettingsStore: CustomerSettingsStore,
    private kendoAlertService: KendoAlertService,
    private printService: IPrintService,
    private router: Router,
    private securityService: SecurityService,
    private warehouseService: WarehouseService,
    private translateSerive: TranslateService,
  ) {
    this.getCurrentAccount();
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(() => {
      this.loadAccountInformation();
      this.currentUserContactStore.get().subscribe(contact => this.currentContact = contact);
    });

    const isNorway = this.customer?.TikkurilaCompanyRegisterCode === CompanyCode.Norway;

    if (isNorway) {
      const newItem = {
          title: 'qrcode.QrCode',
          icon: this.icons.qrCode,
          action: () => this.openQrCodeDialog(),
          type: CardType.Button,
          buttonIcon: this.icons.go
      };

      this.items.splice(1, 0, newItem);
    }
  }

  public labelContent = (e) => {
    return e.value;
  }

  public labelContentDonut = (e) => {
    return e.category;
  }

private getCurrentAccount() {
  this.securityService.getCurrentAccount().subscribe(currentAccount => {
    this.currentAccount = currentAccount;
    this.customerService.getCustomerByCode(currentAccount?.ntw_axcode).subscribe(customer => {
      this.customer = customer;
    });
  })
}

  private loadAccountInformation() {
    this.loading = true;
    const account = this.securityService.getCurrentAccountObject();
    this.customerService.getCustomerByCode(account.ntw_axcode).subscribe({
      next: customer => {
        if (!customer) {
          this.kendoAlertService.showErrorAlert("alerts.errorDuringDataRetrieve");
        }
        if (customer) {
          this.customer = customer;
          this.setCustomerTypeFlags();
          this.companyAdminConfigurationStore.load(this.customer.TikkurilaCompanyRegisterCode).subscribe(this.getDashboardCards.bind(this));
          this.customerSettingsStore.load(customer.bkCustomerKey).subscribe(settings => {
            if (settings) {
              this.customerSettings = settings;
              this.prefferedWarehouse = this.getPrefferedWarehouse();
            }
          });
        }
        this.loading = false;
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
        this.loading = false;
      }
    });

    this.warehouseService.get().subscribe({
      next: warehouses => {
        if (warehouses) {
          this.warehouses = warehouses;
          this.warehousesLoading = false;
        }
        this.prefferedWarehouse = this.getPrefferedWarehouse();
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
        this.warehousesLoading = false;
      }
    });
  }

  private setCustomerTypeFlags() {
    if (this.customer) {
      const salesDistrict = this.customer.SalesDistrict;
      this.isAlcro = salesDistrict && salesDistrict.startsWith(SalesDistrict.AlcroSweden);
      this.isBeckers = salesDistrict && salesDistrict.startsWith(SalesDistrict.BeckersSweden);
    }
  }

  private getDashboardCards(config: CompanyAdminConfiguration): void {
    if (config) {
      this.dashboardCards = config.dashboardCards.filter(c => {
        if (!c.visibleFor || c.visibleFor === DashboardCardVisibleFor.None) {
          return false;
        }
        if (this.isAlcro && c.visibleFor === DashboardCardVisibleFor.Alcro) {
          return true;
        }
        if (this.isBeckers && c.visibleFor === DashboardCardVisibleFor.Beckers) {
          return true;
        }
        return c.visibleFor === DashboardCardVisibleFor.All;
      })
    }
  }

  public getPrefferedWarehouse(): Warehouse | undefined {
    if (this.customerSettings && this.warehouses && this.warehouses.length > 1) {
      return this.warehouses.find(w => w.bkWarehouseKey === this.customerSettings.bkWarehouseKey);
    }
    else if (this.warehouses && this.warehouses.length === 1) {
      const warehouse = this.warehouses[0];
      if (!this.customerSettings || this.customerSettings.bkWarehouseKey !== warehouse.bkWarehouseKey) {
        this.selectedWarehouseChanged(warehouse).subscribe();
      }
      return warehouse;
    }
    return undefined;
  }

  public selectedWarehouseChanged(warehouse: Warehouse): Observable<void> {
    if (!warehouse || !warehouse.bkWarehouseKey) {
      return of();
    }

    this.warehousesLoading = true;

    return new Observable<void>(observer => {
      if (!this.customerSettings) {
        this.customerSettings = <CustomerSettings>{
          bkCustomerKey: this.customer?.bkCustomerKey,
          bkWarehouseKey: warehouse.bkWarehouseKey,
        };

        this.customerSettingsService.create(this.customerSettings).subscribe({
          next: () => {
            this.customerSettingsStore.reload().subscribe(() => {
              this.warehousesLoading = false;
              observer.next();
              observer.complete();
            });
          },
          error: error => {
            this.warehousesLoading = false;
            this.kendoAlertService.showErrorAlert(error);
            observer.error(error);
          }
        });
      }
      else {
        this.customerSettings.bkWarehouseKey = warehouse.bkWarehouseKey;

        this.customerSettingsService.update(this.customerSettings).subscribe({
          next: () => {
            this.customerSettingsStore.reload().subscribe(() => {
              this.warehousesLoading = false;
              observer.next();
              observer.complete();
            });
          },
          error: error => {
            this.warehousesLoading = false;
            this.kendoAlertService.showErrorAlert(error);
            observer.error(error);
          }
        });
      }
    });
  }

  public searchValue: string = '';

  public searchValueChange(value: string) {
    this.searchValue = value;
  }

  public searchForProducts() {
    if (!this.searchValue) {
      return;
    }
    if (!this.prefferedWarehouse) {
      this.showSelectWarehouseDialog = true;
      return;
    }
    this.router.navigate(['./draftorder'], { relativeTo: this.activeRoute, state: { productSearch: this.searchValue } });
  }

  public showSelectWarehouseDialog = false;
  public selectWarehouseDialogClosed(warehosue) {
    this.showSelectWarehouseDialog = false;
    this.loading = true;
    if (warehosue) {
      const subscription = this.selectedWarehouseChanged(warehosue).subscribe({
        next: () => {
          this.loading = false;
          subscription.unsubscribe();
          this.router.navigate(['./draftorder'], { relativeTo: this.activeRoute, state: { productSearch: this.searchValue } });
        },
        error: () => {
          this.loading = false;
          subscription.unsubscribe();
        }
      });
    }
  }

  public onUserGuideClick() {
    const siteUrl = window.location.href.split('/').slice(0, 3).join('/');

    window.open(`${siteUrl}/assets/user_guide_EN.pdf`, '_blank');
  }

  public onMyCartClick() {
    this.router.navigate(['./openorders'], { relativeTo: this.activeRoute });
  }

  public canCreatePrintAccountStatementRequest(): boolean {
    return this.currentContact
      && this.currentContact.emailaddress1
      && this.customer
      && this.customer.bkCustomerKey
      && this.customer.bkCustomerKey.length > 0;
  }

  public printAccountStatement() {
    this.isSubmittingPrintAccountStatementRequest = true;

    this.printService.printAccountStatement(this.currentContact.emailaddress1, this.customer).subscribe({
      next: response => {
        if (!response.isOk) {
          this.kendoAlertService.showErrorAlert(response.errorMsg);
        }
        else {
          this.kendoAlertService.showSuccessAlert("alerts.printAccountStatementRequestSubmittedSuccessfully", true);
        }
        this.isSubmittingPrintAccountStatementRequest = false;
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
        this.isSubmittingPrintAccountStatementRequest = false;
      }
    });
  }

  public async openQrCodeDialog(): Promise<void> {
    try {
      this.getCurrentAccount();
      this.loadAccountInformation();
      if (!this.currentAccount) {
        this.generatedQrCodeData = await QRCode.toDataURL(this.translateSerive.instant('qrcode.noIdentifier'), { errorCorrectionLevel: 'H', type: 'svg' });
      } else {
        const customerNumber = this.currentAccount.ntw_axcode;
        const contactName = this.currentContact.fullname;
        let mdsStatusText ='';

        if (this.currentAccount.ntw_mdsaccountstatus === MdsStatus.Active || this.currentAccount.ntw_mdsaccountstatus === MdsStatus.NotBlocked) {
        mdsStatusText = this.translateSerive.instant('qrcode.mdsStatusNotBlocked');
      } else {
        mdsStatusText = this.translateSerive.instant('qrcode.mdsStatusBlockedForAll');
      }
        // const removeDiacritics = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // const qrData = removeDiacritics(`${customerNumber}\r\n${contactName}\r\n${mdsStatusText}`);
        //console.log(`${customerNumber}\r\n${contactName}\r\n${mdsStatusText}`);
        // this.generatedQrCodeData = await QRCode.toDataURL(qrData, { errorCorrectionLevel: 'L' });
        const qrData = `${customerNumber}\r\n${contactName}\r\n${mdsStatusText}`;
        console.log(qrData);
        this.generatedQrCodeData = await QRCode.toDataURL(qrData, { errorCorrectionLevel: 'H', type: 'svg' });
      }
      this.isQrCodeDialogVisible = true;
    } catch (err) {
      console.error(this.translateSerive.instant('qrcode.generateQrCodeError'), err);
    }
  }

  public closeQrCodeDialog = (): void => {
    this.isQrCodeDialogVisible = false;
  };
}
