<div class="row header function-header">
    <div class="col">
      <label class="headerBig">{{ title | translate | uppercase }}</label>
    </div>
    <div class="col-auto d-flex align-items-center">
      <kendo-svg-icon [icon]="icon" size="xxlarge"></kendo-svg-icon>
    </div>
  </div>
  <div class="row function-details justify-content-end">
    <div *ngIf="type === 'search'" class="col">
      <kendo-textbox
        size="large"
        [placeholder]="inputPlaceholder | translate"
        [(ngModel)]="searchValue"
        (ngModelChange)="onSearchValueChange()"
        (keydown.enter)="onSearch()"
      >
        <ng-template kendoTextBoxSuffixTemplate>
          <button
            kendoButton
            class="primaryButton searchButton"
            [disabled]="!searchValue"
            (click)="onSearch()"
          >
            {{ 'shared.search' | translate }}
          </button>
        </ng-template>
      </kendo-textbox>
    </div>
    <div *ngIf="type === 'button'" class="col-6 ps-0">
      <button
        kendoButton
        size="large"
        class="primaryButton goButton w-100"
        (click)="action()"
      >
        <div class="row w-100">
          <div class="col-auto d-flex align-items-center">
            <kendo-svg-icon [icon]="buttonIcon" size="large"></kendo-svg-icon>
          </div>
          <div class="col">
            {{ 'shared.go' | translate }}
          </div>
        </div>
      </button>
    </div>
  </div>
  