<ntw-loading-panel *ngIf="loading"></ntw-loading-panel>
<ntw-select-warehouse-dialog *ngIf="showSelectWarehouseDialog" [warehouses]="warehouseDropdownListData"
    (warehouseSelected)="selectWarehouseDialogClosed($event)">
</ntw-select-warehouse-dialog>
<form class="accountData" *ngIf="customer && !loading">
    <div class="container">
        <div class="row header">
            <div class="col">
                <label class="headerBig"> {{customer.CustomerName}} </label>
            </div>
            <div class="col-1 col-sm-6 col-md-auto d-flex justify-content-end align-items-center">
                <ntw-loading-button class="mx-2 d-none d-md-block" buttonClass="primaryButton"
                    (click)="printAccountStatement()" [disabled]="!canCreatePrintAccountStatementRequest()"
                    [loading]="isSubmittingPrintAccountStatementRequest"
                    [text]="'account.printAccountStatement' | translate">
                </ntw-loading-button>
                <button class="collapseButton inline" (click)="isCollapsed = !isCollapsed"
                    [@rotate]="isCollapsed ? 'collapsed' : 'expanded'" [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample">
                    <kendo-svg-icon [icon]="icons.chevronDown" size="xxlarge"></kendo-svg-icon>
                </button>
            </div>
        </div>
        <div class="row details row-cols-1 row-cols-sm-2 gy-3 pt-0" [@collapse]="isCollapsed ? 'closed' : 'open'">
            <div class="col w-100 d-md-none mt-3">
                <ntw-loading-button class="w-100 printButton" buttonClass="primaryButton w-100"
                    buttonStyle="height: 36px;" (click)="printAccountStatement()"
                    [disabled]="!canCreatePrintAccountStatementRequest()"
                    [loading]="isSubmittingPrintAccountStatementRequest"
                    [text]="'account.printAccountStatement' | translate">
                </ntw-loading-button>
            </div>
            <div class="col">
                <div class="row row-cols-1 row-cols-sm-2 gy-sm-3">
                    <div class="col">
                        <label class="labelGrey">{{ 'account.axcode' | translate }}</label>
                    </div>
                    <div class="col">
                        <label class="lableBlack fw-bolder">{{customer.AXCode}}</label>
                    </div>
                    <div class="col mt-2 mt-sm-3">
                        <label class="labelGrey">{{ 'account.currencycode' | translate }}</label>
                    </div>
                    <div class="col">
                        <label class="lableBlack fw-bolder">{{customer.CurrencyCode}}</label>
                    </div>
                    <div class="col mt-2 mt-sm-3">
                        <label class="labelGrey">{{ 'account.paymentterms' | translate }}</label>
                    </div>
                    <div class="col">
                        <label class="lableBlack fw-bolder">{{customer.PaymentTermDescription}}</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-cols-1 row-cols-sm-2 gy-sm-3">
                    <div class="col mt-0 mt-sm-3">
                        <label class="labelGrey">{{ 'account.legaladdress' | translate }}</label>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <label class="lableBlack fw-bolder">{{customer.StreetAddress1}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="lableBlack fw-bolder">{{customer.PostalCode}} {{customer.City}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="lableBlack fw-bolder">{{customer.CountryName}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col mt-2">
                        <label class="labelGrey">{{ 'account.yourWarehouse' | translate }}</label>
                    </div>
                    <div class="col mt-1 mt-sm-2">
                        <ntw-loading-dropdownlist [loading]="!warehouses || warehouses.length === 0"
                            [disabled]="!warehouses || warehouses.length === 0 || warehouses.length === 1"
                            [showNgContentOnDisabled]="true"
                            [defaultItem]="prefferedWarehouse ?? { warehouseName: 'account.selectWarehouse' | translate }"
                            (valueChange)="selectedWarehouseChanged($event).subscribe()"
                            [data]="warehouseDropdownListData" textField="warehouseName" valueField="bkWarehouseKey">
                            <label class="lableBlack fw-bolder">{{prefferedWarehouse?.warehouseName ?? ''}}</label>
                        </ntw-loading-dropdownlist>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="container my-3" *ngIf="!loading">
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 function-rows justify-content-between">
      <div
        *ngFor="let item of items"
        class="col col-md-12"
        [ngClass]="{
          'col-xl-3': items.length === 4,
          'col-xl-4': items.length === 3
        }"
      >
      <ntw-account-detail-card
      [title]="item.title"
      [icon]="item.icon"
      [type]="item.type"
      [action]="item.action"
      [inputPlaceholder]="item.inputPlaceholder"
      [buttonIcon]="item.buttonIcon"
      [(searchValue)]="searchValue" 
      (searchValueChange)="searchValueChange($event)"
    ></ntw-account-detail-card>
    
      </div>
    </div>
  </div>

<ntw-qr-code-dialog
  *ngIf="isQrCodeDialogVisible"
  [close]="closeQrCodeDialog"
  [qrCodeData]="generatedQrCodeData"
>
</ntw-qr-code-dialog>

<ntw-dashboard-card class="my-3" *ngFor="let card of dashboardCards" [card]="card"></ntw-dashboard-card>