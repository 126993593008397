import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { CrmAccount } from 'src/app/shared/models/account';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { environment } from 'src/environments/environment';
import { v4 as Guid } from 'uuid';
import { IAccountService } from './account.service.interface';

@Injectable()
export class AccountService implements IAccountService {
  baseUrl: string;
  accountSelector = "accountid,name,_parentaccountid_value,ntw_axcode,ntw_shortname,ntw_mdsaccountstatus";

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  private accounts$: Observable<CrmAccount[]>;

  getAccounts(): Observable<CrmAccount[]> {
    const url = this.baseUrl + `accounts?$select=${this.accountSelector}`;
    if (!this.accounts$) {
      this.accounts$ = this.httpClient.get<ODataResponse<CrmAccount[]>>(url).pipe(
        map(x => { return x?.value; }),
        catchError(error => {
          error['sharedErrorGuid'] = error['sharedErrorGuid'] ?? Guid();
          throw error;
        }),
        share(),
      );
    }
    return this.accounts$;
  }

  getChildAccounts(guid: string): Observable<CrmAccount[]> {
    const url = this.baseUrl + "accounts" + "?$filter=_parentaccountid_value eq " + guid;
    return this.httpClient.get<ODataResponse<CrmAccount[]>>(url).pipe(map(x => { return x?.value; }));
  }

  getCrmAccountById(accountId: string): Observable<CrmAccount> {
    const url = this.baseUrl + "accounts" + `?$select=${this.accountSelector}&$filter=accountid eq ` + accountId;
    return this.httpClient.get<ODataResponse<CrmAccount[]>>(url).pipe(map(x => { return x?.value[0]; }));
  }
}
