export interface CrmAccount {
  accountid: string;
  name: string;
  _parentaccountid_value: string;
  ntw_axcode: string;
  ntw_shortname: string;
  ntw_mdsaccountstatus: MdsStatus
}

export enum MdsStatus {
  Active = 1,
  InActive = 2,
  BlockedForAll = 3,
  BlockedForInvoice = 4,
  NotBlocked = 5,
  BlockedForPayment = 6
}
