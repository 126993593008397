import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'ntw-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss']
})
export class QrCodeDialogComponent implements OnInit, OnDestroy {
  @Input() public close: () => void;
  @Input() public qrCodeData: string;
  public countdownClass = 'countdown-green';
  public loading = true;
  public countdown: number = 59;
  private countdownSubscription: Subscription;

  ngOnInit(): void {
    this.loading = false;
    this.startCountdown();
  }

  ngOnDestroy(): void {
    this.stopCountdown();
  }

  private startCountdown(): void {
    this.countdownSubscription = interval(1000).subscribe(() => {
      this.countdown--;
      this.countdownClass = this.countdown <= 10 ? 'countdown-pulse' : 'countdown-green';
      if (this.countdown <= 0) {
        this.stopCountdown();
        this.close();
      }
    });
  }

  private stopCountdown(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  public getFormattedCountdown(): string {
    return `0:${this.countdown.toString().padStart(2, '0')}`;
  }
}

