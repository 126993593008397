import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { State } from "@progress/kendo-data-query";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { KendoUtils } from "src/app/shared/kendo-utils";
import { ColorShade } from "src/app/shared/models/colorshade/colorshade-value";
import { CompanyAdminConfiguration } from "src/app/shared/models/company-admin-configuration";
import { ODataResponse } from "src/app/shared/models/odata-response";
import { PortalOrder, PortalOrderStatus } from "src/app/shared/models/order-creation/portal-order";
import { PortalOrderProduct } from "src/app/shared/models/order-creation/portal-order-product";
import { CompanyAdminConfigurationStore } from "src/app/shared/stores/company-admin-configuration.store";
import { environment } from "src/environments/environment";
import { CreateOrderResponse, IPortalOrderService } from "./portal-orders.service.interface";


@Injectable({
    providedIn: 'root'
})

export class PortalOrderService implements IPortalOrderService {
    public baseUrl = ""

    constructor(private httpClient: HttpClient,
        private translateService: TranslateService,
        private companyAdminConfigurationStore: CompanyAdminConfigurationStore) {
        this.baseUrl = environment.apiBaseUrl + "portalorders";
    }

    public GetPortalOrderDetailsById(customerCode: string, recentOrderId: string): Observable<PortalOrder> {
        const url = this.baseUrl
            + `?$top=1`
            + `&$filter=((ID eq '${recentOrderId}' or PortalOrderId eq '${recentOrderId}') and bkCustomerNumber eq '${customerCode}')`;

        return this.httpClient.get<ODataResponse<PortalOrder[]>>(url).pipe(map(response => {
            if (response && response.value && response.value[0]) {
                const order = response.value[0];
                this.calculateFieldsFromProducts(order);
                this.setStatusName(order);
                return order;
            }
            return null;
        }));
    }

    public GetCustomerPortalOrders(customerCode: string): Observable<PortalOrder[]> {
        const url = this.baseUrl
            + `?$top=100`
            + `&$filter=(LogicalName eq 'PortalOrder' and bkCustomerNumber eq '${customerCode}' and orderSubmissionStatus ne ${PortalOrderStatus.PlacedInAx})`
            + `&$orderby=CreatedOn desc`
            + `&$count=true`;

        return this.httpClient.get<ODataResponse<PortalOrder[]>>(url).pipe(map(oDataResponse => {
            if (!oDataResponse || !oDataResponse.value) {
                return null;
            }
            const orderArray = oDataResponse.value;
            orderArray.forEach(order => {
                this.calculateFieldsFromProducts(order);
                this.setStatusName(order);
            });
            return orderArray.sort((x, y) => y?.requestedDeliveryDate?.getTime() ?? 0 - x?.requestedDeliveryDate?.getTime() ?? 0);
        }));
    }

    public GetCustomerPortalOrdersWithGridState(customerCode: string, gridState: State): Observable<PortalOrder[]> {
        let oDataFilters = KendoUtils.toODataString(gridState);
        const filterQuery = `(LogicalName eq 'PortalOrder' and bkCustomerNumber eq '${customerCode}' and orderSubmissionStatus ne ${PortalOrderStatus.PlacedInAx})`;
        const filter = "$filter=";

        if (oDataFilters.indexOf(filter) != -1) {
            let gridStateFilter = oDataFilters.substring(oDataFilters.indexOf(filter) + filter.length);

            if (gridStateFilter.indexOf("&$") != -1) {
                gridStateFilter = gridStateFilter.substring(0, gridStateFilter.indexOf("&$"));
            }

            oDataFilters = oDataFilters.replace(gridStateFilter, `(${gridStateFilter} and (${filterQuery}))`);
        } else {
            oDataFilters = `$filter=${filterQuery}&${oDataFilters}`;
        }
        oDataFilters = oDataFilters.replace("orderNumber", "PortalOrderId");

        const url = this.baseUrl
            + `?${oDataFilters}`
            + `&$count=true`;

        return this.httpClient.get<ODataResponse<PortalOrder[]>>(url).pipe(map(oDataResponse => {
            if (!oDataResponse || !oDataResponse.value) {
                return null;
            }
            const orderArray = oDataResponse.value;
            orderArray.forEach(order => {
                this.calculateFieldsFromProducts(order);
                this.setStatusName(order);
            });
            return orderArray.sort((x, y) => y?.requestedDeliveryDate?.getTime() ?? 0 - x?.requestedDeliveryDate?.getTime() ?? 0);
        }));
    }

    public GetDraftOrderByUserId(userId: string, customerCode: string): Observable<PortalOrder> {
        const url = this.baseUrl
            + `?$top=1`
            + `&$filter=(ownerUserb2cId eq '${userId}' and bkCustomerNumber eq '${customerCode}' and orderSubmissionStatus eq ${PortalOrderStatus.Draft})`;

        return this.httpClient.get<ODataResponse<PortalOrder[]>>(url).pipe(map(response => {
            if (response && response.value && response.value[0]) {
                const order = response.value[0];
                this.calculateFieldsFromProducts(order);
                this.setStatusName(order);
                return order;
            }
            return null;
        }));
    }

    public CreateOrder(order: PortalOrder): Observable<CreateOrderResponse> {
        const url = this.baseUrl;
        return this.httpClient.post(url, order);
    }

    public UpdateOrder(order: PortalOrder): Observable<unknown> {
        const url = this.baseUrl + "/update";
        return this.httpClient.patch(url, order);
    }

    public SubmitOrder(order: PortalOrder): Observable<unknown> {
        const url = this.baseUrl + "/submit";
        return this.httpClient.post(url, order);
    }

    public DeleteOrder(order: PortalOrder): Observable<unknown> {
        const url = this.baseUrl + `/${order.id}`;
        return this.httpClient.delete(url);
    }

    public setStatusName(order: PortalOrder) {
        if (order?.orderSubmissionStatus == PortalOrderStatus.Draft) {
            order.orderSubmissionStatusName = this.translateService.instant("portalOrder.status.draft");
        }
        else if (order?.orderSubmissionStatus == PortalOrderStatus.Submitted) {
            order.orderSubmissionStatusName = this.translateService.instant("portalOrder.status.submitted");
        }
        else if (order?.orderSubmissionStatus == PortalOrderStatus.PlacedInAx) {
            order.orderSubmissionStatusName = this.translateService.instant("portalOrder.status.placedInAx");
        }
        else {
            order.orderSubmissionStatusName = this.translateService.instant("portalOrder.status.unknownStatus");
        }
    }

    public calculateFieldsFromProducts(order: PortalOrder) {
        this.companyAdminConfigurationStore.get().subscribe(companyAdminConfiguration => {
            if (order.orderProducts?.length == 0) {
                return;
            }
            order.orderProducts.sort((x, y) => x.orderProductNumber > y.orderProductNumber ? 1 : -1);
            order.totalProducts = order.orderProducts?.length;
            let totalValue = 0;
            let totalVolumeL = 0;
            let totalNetWeight = 0;
            let totalGrossWeight = 0;
            let totalPalletQty = 0;

            order.orderProducts.forEach(orderLine => {
                if (!orderLine.quantity) {
                    return;
                }
                const quantity = orderLine.quantity;
                if (orderLine.unitNetPrice) {
                    totalValue += orderLine.unitNetPrice * quantity;
                }
                if (orderLine.volumeL) {
                    totalVolumeL += orderLine.volumeL * quantity;
                }
                if (orderLine.netWeightKG) {
                    totalNetWeight += orderLine.netWeightKG * quantity;
                }
                if (orderLine.grossWeightKG) {
                    totalGrossWeight += orderLine.grossWeightKG * quantity;
                }
                if (orderLine.palletSizeName && +orderLine.palletSizeName) {
                    const palletSize = +orderLine.palletSizeName;
                    totalPalletQty += quantity / palletSize;
                }
                this.setColorShadeProperties(orderLine);
            });
            order.totalValue = totalValue;
            order.totalVolumeLiters = totalVolumeL;
            order.totalNetWeight = totalNetWeight;
            order.totalGrossWeight = totalGrossWeight;
            order.totalPalletQty = totalPalletQty;
            this.calculateVatFields(order, companyAdminConfiguration);
        });
    }

    private calculateVatFields(order: PortalOrder, companyAdminConfiguration: CompanyAdminConfiguration) {
        if (!companyAdminConfiguration || !companyAdminConfiguration.vatRate || !order.totalValue) {
            return;
        }

        const vatValue = companyAdminConfiguration.vatRate / 100.0;

        order.totalVat = order.totalValue * vatValue;
        order.totalValueWithVat = order.totalValue + order.totalVat;
    }

    private setColorShadeProperties(orderLine: PortalOrderProduct) {
        if (orderLine.chosenColorShade != null && !(orderLine.chosenColorShade instanceof ColorShade)) {
            orderLine.chosenColorShade = new ColorShade(orderLine.chosenColorShade);
        }
    }
}
