<kendo-dialog title="{{ 'qrcode.generateQrCode' | translate }}" (close)="close()" maxHeight="90vh" [maxWidth]="800"
minWidth="250px;">
    <ntw-loading-panel *ngIf="loading" [fullscreen]="false" themeColor="primary"></ntw-loading-panel>
    <ng-container *ngIf="!loading">
        <div class="container text-center">
          <div class="qr-code-container mb-4" *ngIf="qrCodeData">
            <img [src]="qrCodeData" alt="{{ 'qrcode.generateQrCode' | translate }}" class="img-fluid" style="min-width: 250px;" />
          </div>
          <div class="text-center countdown" [ngClass]="countdownClass">
            <p><strong>{{ getFormattedCountdown() }}</strong></p>
          </div>          
        </div>
      </ng-container>
    <kendo-dialog-actions>
      <button kendoButton (click)="close()">{{ 'shared.close' | translate }}</button>
      </kendo-dialog-actions>
</kendo-dialog>